<template>
  <div>
    <OnboardingHeader>
    </OnboardingHeader>
    <div class="tw-pt-28">
      <div v-if="loading">
        <Loader/>
      </div>
      <div v-else>
        <div @click="goBack" class="tw-flex tw-cursor-pointer md:tw-m-auto tw-ml-5 md:tw-pb-8 tw-pb-3  md:tw-w-10/12">
          <vue-feather type="arrow-left"></vue-feather>
          <h4 class=" md:tw-block tw-hidden">Back</h4>
        </div>
        <div class="assessment_details tw-p-6 tw-m-auto md:tw-w-8/12">
          <div class="tw-flex">
            <h3 class="tw-mb-10 tw-text-18 md:tw-text-24">{{assessmentDetails.job_title || assessmentDetails.program_title}} Assessment</h3>

          </div>
          <v-row  class="tw-flex md:tw-flex-row tw-flex-col md:tw-justify-between">
            <v-col cols="12" md="6" sm="12">
              <div>
                <div v-for="(data,i) in allTest" :key="i" >
                  <div @click="goToInstructionPage(data.title,data.time, assessmentDetails.job_title)"  class="tw-mb-6 md:tw-w-500" :style="[data.enable?{color:'#001343',cursor:'pointer'}:{color:'#9CABB5',pointerEvents:'none',cursor:'not-allowed'}]">
                    <div class="tw-flex tw-justify-between">
                      <div>
                        <h2 >{{ data.title }}</h2>
                        <div v-if="data.time" class="tw-flex tw-items-center" >
                          <vue-feather type="clock" style="width:18px"></vue-feather>
                          <span class="tw-ml-2" >{{ data.time }} min</span>
                        </div>
                      </div>
                      <div v-if="data.completed" class="completed">
                        <h6>COMPLETED</h6>
                      </div>
                      <div v-else>
                        <vue-feather type="arrow-right"></vue-feather>
                      </div>
                    </div>
                    <div class="tw-my-6" >
<!--                      <v-divider v-if="i<allTest.length-1"/>-->
                      <v-divider />
                    </div>
                  </div>
                </div>
                <div v-if="this.$route.query.from && allTest.length">
                  <base-button class=" tw-mt-6 tw-cursor-pointer tw-float-right" button-text="Continue" @click="goBack" width="'160'" :disabled="checkIfTestIsCompleted"/>
                </div>
              </div>
              <div v-if="assessmentDetails.external_test && assessmentDetails.external_test.length" class="tw-mb-6 md:tw-w-500">
                <div class="tw-my-6" >
                  <v-divider />
                </div>
                <div v-if="assessmentDetails.external_test && assessmentDetails.external_test.length" @click="goToNextStepPage()" class="tw-cursor-pointer" :style="[enableOrDisableSkill?{color:'#001343',cursor:'pointer'}:{color:'#9CABB5',pointerEvents:'none',cursor:'not-allowed'}]">
                  <div class="tw-flex tw-justify-between">
                    <h2 >Skill Assessment</h2>
                    <div>
                      <vue-feather type="arrow-right"></vue-feather>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="3" sm="0"></v-col>
            <v-col v-if="assessmentDetails.due_date" cols="12" md="3" sm="12" class="due tw-p-6 tw-w-full md:tw-w-72">
              <div class="tw-flex  tw-mt-3" style="margin:auto">
                <vue-feather style="color:#008EEF; width:21px" type="calendar"/>
                <div class="tw-ml-4">
                  <h1>Due in {{processDay(assessmentDetails.due_date).split('ago').join(' ')}}</h1>
                  <span>{{formatDate(assessmentDetails.due_date)}}</span>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingHeader from "@/components/reusables/OnboardingHeader";
import {
  getAllScreeningTestForJobAssessment,
  getAllScreeningTestForProgramAssessment,
  getAssessmentStatus, getProgramAssessmentStatus
} from "@/services/api/APIService";
import timeDay from "@/mixins/timeDay.mixin";
import moment from "moment";
import Loader from "@/Loader";
import BaseButton from "@/components/reusables/BaseButton.vue";


export default {
  name: "AssessmentTestType",
  components: {BaseButton, Loader, OnboardingHeader},
  mixins:[timeDay],

  data() {
    return {
      assessmentDetails: [],
      allTest:[],
      allStatus: {},
      loading:false,
    }
  },
  watch:{
    allTest:{
      handler(){
        if(this.allTest.length){
          this.enableTest()
        }
      },
      immediate:true,
    }
  },
  created() {
    this.getAllTestType()
  },
  computed: {
    getLoginUser() {
      return JSON.parse(sessionStorage.getItem('userData')) || null;
    },
    formatDate() {
      return function (date) {
        return moment(date).format('Do MMMM YYYY');
      }
    },

    checkIfTestIsCompleted() {
      let testArray = Object.values(this.allTest)
      return testArray.some(item => item.completed !== true)
    },

    enableOrDisableSkill() {
      let statusArray = Object.values(this.allStatus)
      return statusArray.every(item => item === true)
    }
  },
  methods: {
    enableTest(){
      let test = this.allTest
      if(test.length) {
        for (let i = 0; i < test.length; i++) {

          let previous = test[i - 1];
          let current = test[i];
          // console.log(current,'current')
          // console.log(previous,'previous')

          if(i===0 && !current.completed){
            current.enable = true
          }
          else if(i>0 && previous.completed && !current.completed){
            current.enable = true
          }
          else{
            current.enable = false
          }
        }

      }
    },

    goToInstructionPage(title,time){
        this.$router.push({name: 'AssessmentInstructionView',query:{...this.$route.query,category:title,time:time,jobId:this.$route.query.id}})
          // query:{jobId:this.$route.query.id,programId:this.$route.query.id,category:title,time:time,company_name:this.$route.query.company_name,job_title:this.$route.query.job_title,program_title:this.$route.query.program_title},})
      },
    goToNextStepPage(){
      this.$router.push({name: 'AssessmentInstructionView',query:{...this.$route.query,job_title:this.$route.query.job_title,is_external_test:true,jobId:this.$route.query.id, test_id: this.assessmentDetails.external_test}})
    },
        async getAllTestType(){
        this.loading = true
          if(this.$route.query.from){
            getAllScreeningTestForProgramAssessment(this.$route.query.id)
                .then(res=>{
                  console.log(res.data)
                  this.assessmentDetails = res.data
                  this.allTest = Object.entries(res.data.categories).map((e) => ({
                    title: e[1].name,
                    time: e[1].time_for_test,
                    enable: false
                  }));
                  this.getAllAssessmentStatus()

                })
                .catch(err=>{
                  console.log(err)
                })
                .finally(()=>this.loading=false)
          }
          else{
            getAllScreeningTestForJobAssessment(this.$route.query.id)
                .then(res=>{
                  console.log(res.data)
                  this.assessmentDetails = res.data
                  this.allTest = Object.entries(res.data.categories).map((e) => ({
                    title: e[1].name,
                    time: e[1].time_for_test,
                    enable: false
                  }));
                  this.getAllAssessmentStatus()

                })
                .catch(err=>{
                  console.log(err)
                })
                .finally(()=>this.loading=false)
          }

        },
    async getAllAssessmentStatus(){
      if(this.$route.query.from){
        getProgramAssessmentStatus(this.$route.query.id)
            .then(res=>{
              this.allStatus = res.data
              this.allTest = this.allTest
                  .map(({time, title,enable}) => ({time, title,enable, completed: res.data[`${title.split(' ')[0]}_tests`.toLowerCase()]}))
            })
      }
      else{
        getAssessmentStatus(this.getLoginUser.email,this.$route.query.id)
            .then(res=>{
              this.allStatus = res.data
              this.allTest = this.allTest
                  .map(({time, title,enable}) => ({time, title,enable, completed:title==='Aptitude Test'?res.data['psychometric_tests'] : res.data[`${title.split(' ')[0]}_tests`.toLowerCase()]}))
            })
      }

    },
    goBack(){
      let token = sessionStorage.getItem('userToken')
      if(this.$route.query.from){
      //   window.location.href =
      //       `${process.env.VUE_APP_LEARNER_FRONTEND_URL}/redirect?token=${token}
      //   &from=${this.$route.name}&to=${this.$route.query.from}&id=${this.$route.query.id}&programName=${this.$route.query.program_title}&view=process`
        window.location.href =
            `${process.env.VUE_APP_LEARNER_FRONTEND_URL}/explore/application-progress?token=${token}
        &id=${this.$route.query.id}&programName=${this.$route.query.program_title}&view=process&site_name=${this.$route.query.site_name}&company_name=${this.$route.query.company_name}`
      }
      else {
        this.goBackToAppliedJob()

      }
    },
    goBackToAppliedJob(){
      this.$router.push({name:'AppliedJobDetails',params:{job_id:this.$route.query.id},query:{company_name:this.$route.query.company_name,job_title:this.$route.query.job_title}})
    }
  }
}
</script>

<style scoped>
.assessment_details h2{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  /*color: #1E323F;*/
  margin-bottom: 12px;

}
.assessment_details h3{
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #008EEF;
}
.assessment_details span{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /*color: #9CABB5;*/

}
.due{
  background: #F6FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  padding-top: 25px;
  padding-bottom: 25px;
  max-height: 100px;
}
.assessment_details h1{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Enum-Grey/2 */

  color: #475661;

}
.completed{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;

  color: #076F56;

}
.disable-skill{
  background-color: red;
}
.enable-skill{
  background-color:green;
}
</style>
