export default {
    methods:{
        processDay(date_created) {
            if (!date_created) return;
            let day;
            let date, now
            day = date_created.split("T")[0]
            let d1 = date = new Date(day);
            let d2 = now = new Date();
            if (d2.getTime() < d1.getTime()) {
                d1 = now;
                d2 = date;
            }
            let yd = d1.getYear();
            let yn = d2.getYear();
            let years = yn - yd;
            let md = d1.getMonth();
            let mn = d2.getMonth();
            let months = mn - md;
            if (months < 0) {
                years--;
                months = 12 - md + mn;
            }
            let dd = d1.getDate();
            let dn = d2.getDate();
            let days = dn - dd;
            if (days < 0) {
                months--;
                // figure out how many days there are in the last month
                d2.setMonth(mn, 0);
                days = d2.getDate() - dd + dn;
            }
            let weeks = Math.floor(days / 7);
            days = days % 7;
            if (years > 0) return years + ' year' +  (years > 1 ? "s" : "") + " ago"
            if (months > 0) return months + ' month' +  (months > 1 ? "s" : "") + " ago"
            if (weeks > 0) return weeks + ' week' +  (weeks > 1 ? "s" : "") + " ago"
            if(days > 0) return  days + ' day' +  (days > 1 ? "s" : "")  + " ago" ;
            return "Created Today";

            // return day;

        },

    }
}
