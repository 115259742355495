<template>
<div>
  <AssessmentTestType/>
</div>
</template>

<script>
import AssessmentTestType from "@/components/dashboard/jobAssessment/AssessmentTestType";
export default {
name: "AssessmentTestTypeView",
  components: {AssessmentTestType}
}
</script>

<style scoped>

</style>
