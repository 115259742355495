<template>
  <div class="d-flex align-center tw-w-full justify-center">

    <v-progress-circular
        indeterminate
        color="#008EEF"
        class="mt-16"
    />
    <!--    <v-progress-linear-->
    <!--        color="#008EEF"-->
    <!--        indeterminate-->
    <!--        rounded-->
    <!--        height="6"-->
    <!--        class="mt-10"-->
    <!--    />-->
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>